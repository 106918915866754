// Brand colors
$primaryColor: #001A69;
$secondaryColor: #052754;
$tertiaryColor: #ff6f29;

// Common colors
$white: #fff;
$black: #000;
$dark-blue: #001a69;
$background-white:#fff;
$background-black:#000;

//Common body
$body:#f7f7f7;
$body-bg: #ddd;
$body-txtColor: #222;
$body-borderRadius: 4px;
$borderColor: $black;

// min and max width of layout
$page__maxWidth: 1070px;
$page__minWidth: 1070px;

// $eDisplay__maxWidth: 1070px;
// $eDisplay__minWidth: 1070px;

$medium_Device:1070px;
$tab_Device:1023px;

@media screen and (max-width: $medium_Device){
}
@media screen and (max-width: $tab_Device){
}

// Project name
$project-name:".HKJC-stats-center";

// Paths
$fonts-path: "../../fonts";
$flag-path: "../../images";
$img-path: "../../images";

// 2 column layout with 50% each
// $left-panel50:50%;
// $right-panel50:50%;

// icon background colors
$win:#ff0000;
$loss:#22aa4c;
$draw:#ffa500;
$high:#74aa8c;
$low:#ebbf32;
$BTTSno:#ebbf32;
$BTTSyes:#74aa8c;
// $BTTSno:#00545D;
// $BTTSyes:#008E9B;
$winpercent:#22aa4c;
$losspercent:#ff0000;
$drawpercent:#ffa500;

// box shadow effect
$boxshadow:2px 2px 5px #ccc;

// oddsDetails
$homeTeamColor:#09b3c6;
$drawTeamColor:#ffffff;
$awayTeamColor:#f38182;

// Strength meter colors
$color1:#ffcc00;
$color2:#dac045;
$color3:#47bba3;
$color4:#f65d57;
$color5:#f90;
$color6:#6dc36d;
$color7:#0099ff;
$color8:#6851df;
$color9:#777;