body.eDisplay {
    // background-color: $black;
    // padding: 5px;

    // .container {
    //     max-width: $eDisplay__maxWidth;
    //     min-width: $eDisplay__minWidth;
    // }

    .standingTable-group {
        width: 48% !important;
    }

    .playerCountry {
        left: 350px !important;
    }

    // .winPredictor-bestBet {
    //     .winPredictor,
    //     .bestBet {
    //         width: 47%;
    //     }
    // }

    .tableTwo-column {
        flex-wrap: wrap;
        // .tableHome {
        //     width: 100%;
        //     margin-bottom: 20px;
        //     justify-content: center;
        // }
        // .tableAway {
        //     width: 100%;
        //     justify-content: center;
        // }
    }
}